import { transform, isEqual, isObject, cloneDeep } from 'lodash'
import { IForm, IGoal, IInitialCampaignDetail, IMessage, IPushCampaignDetail, IQuestion } from './types'
import { randomString } from './helpers'
import { ICard } from 'bot-flow-maker/src/types'
import { ACTION_FORM, ACTION_GOAL, ACTION_MESSAGES, ACTION_QUESTION } from '@/store/actions'

export const ObjectUtils = {
  // eslint-disable-next-line
  mergeObjectLeft: (obj1: Record<string, any>, obj2: Record<string, any>) => {
    for (const key of Object.keys(obj1)) {
      obj1[key] = obj2[key] ?? obj1[key]
    }

    return obj1
  },

  differenceProperties: <T>(object, base) => {
    return transform(object, (result: T, value, key) => {
      if (!isEqual(value, base[key])) {
        // console.log('Difference detected at path:', value)
        result[key] =
          isObject(value) && isObject(base[key]) ? ObjectUtils.differenceProperties(value, base[key]) : value
      }
    })
  },
  isDifference: (object, base) => {
    return !!Object.keys(ObjectUtils.differenceProperties(object, base)).length
  },

  /* eslint-disable @typescript-eslint/no-explicit-any */
  copyScenario: async (store: any, detail: IInitialCampaignDetail | IPushCampaignDetail) => {
    const { dispatch } = store
    let scenarios = detail.scenarios
    if (scenarios) {
      for (const s of scenarios) {
        if (s.cardType !== 'start') {
          if (s.uniqueId !== 'welcome') {
            // eslint-disable-next-line dot-notation
            s['old_uniqueId'] = cloneDeep(s.uniqueId)
            s.uniqueId = randomString()
          }

          let resource: IMessage | IQuestion | IGoal | IForm = {}
          let action = ''
          if (s.cardType === 'message') {
            action = ACTION_MESSAGES.CLONE
          } else if (s.cardType === 'question') {
            action = ACTION_QUESTION.CLONE
          } else if (s.cardType === 'goal') {
            action = ACTION_GOAL.CLONE
          } else if (s.cardType === 'form') {
            action = ACTION_FORM.CLONE
          }
          const id = cloneDeep(s.id)
          s.id = ''
          if (action !== '') {
            resource = await dispatch(action, id)

            if (resource && resource._id !== '') {
              s.id = resource._id ?? ''
              s.displayId = resource.display_id ?? ''
              s.title = resource.title ?? ''
            }
          }

          console.log(s, 'card')
        }
      }

      scenarios = ObjectUtils.updateUniqueIdNextCard(scenarios)
    }

    detail.scenarios = scenarios
    return detail
  },

  updateUniqueIdNextCard: (scenarios: ICard[]) => {
    for (const scenario of scenarios) {
      for (const answer of scenario.answers) {
        for (const nextCard of answer.nextCards) {
          const uniqueId = ObjectUtils.getUniqueIdNextCard(scenarios, nextCard.uniqueId)
          if (uniqueId) {
            // eslint-disable-next-line dot-notation
            nextCard['old_uniqueId'] = cloneDeep(nextCard.uniqueId)
            nextCard.uniqueId = uniqueId
          }
        }
      }
    }
    return scenarios
  },
  getUniqueIdNextCard: (scenarios: ICard[], uniqueId) => {
    for (const scenario of scenarios) {
      // eslint-disable-next-line dot-notation
      if (scenario['old_uniqueId'] === uniqueId) {
        return scenario.uniqueId
      }
    }
    return null
  },
}
